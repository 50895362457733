var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", [
          _c("img", {
            staticClass: "logo",
            attrs: { src: "https://www.achencybe.com/img/pc/logo.png" },
          }),
          _c("div", { staticClass: "title" }, [_vm._v("Care+计划")]),
          _c(
            "div",
            { staticClass: "content-box" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                { key: item.title, staticClass: "content-item" },
                [
                  _c("h2", [_vm._v(_vm._s(item.title))]),
                  _c("p", [_vm._v(_vm._s(item.content))]),
                  _c(
                    "ul",
                    _vm._l(item.list, function (item) {
                      return _c("li", { key: item }, [_vm._v(_vm._s(item))])
                    }),
                    0
                  ),
                  _c("div", { staticClass: "info" }, [
                    _vm._v(_vm._s(item.info)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }